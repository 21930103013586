@import-normalize; /* bring in normalize.css styles */

body {
  hyphens: auto;
  margin: 0;
  font-family: -apple-system, 'Helvetica Neue', BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.player-wrapper {
  position: absolute;
  height: 0;
  padding-bottom: 56.25%;
}

.text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 7px;
  opacity: 0.3;
  color: gray;
  z-index: 999;
}

.iframe-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* pointer-events: none; */
  /* overflow: hidden; */
}

.iframe-wrapper iframe {
  width: 100vh;
  height: 100vh;
  min-height: 90%;
  min-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hoverScale {
  transition: 0.5s;
}
.hoverScale:hover {
  scale: 1.02;
}

.ta {
  position: relative;
  cursor: auto;
  width: 162px;
  height: 49px;
  border: 3px solid #ff6e30;
  background-color: white;
  font-size: 23px;
  font-weight: 500;
  border-right: none;
}

.ta-fa {
  position: relative;
  cursor: pointer;
  width: 162px;
  height: 49px;
  border: 3px solid black;
  background-color: white;
  font-size: 23px;
  font-weight: 500;
  border-right: none;
}

.seven-fa {
  position: absolute;
  width: 20px;
  height: 49px;
  border-top: 3px solid black;
  border-right: 3px solid black;
  border-left: none;
  top: -3px;
  right: -9px;
  transform: skewX(342deg);
}

.ta-2 {
  position: relative;
  width: 162px;
  height: 49px;
  border: 3px solid black;
  background-color: white;
  font-size: 23px;
  font-weight: 500;
  border-right: none;
  transform: rotate(540deg);
  writing-mode: horizontal-tb;
}

.seven {
  position: absolute;
  width: 20px;
  height: 49px;
  border-top: 3px solid #ff6e30;
  border-right: 3px solid #ff6e30;
  border-left: none;
  top: -3px;
  right: -9px;
  transform: skewX(342deg);
}

.sevenFA {
  position: absolute;
  width: 20px;
  height: 49px;
  border-top: 3px solid black;
  border-right: 3px solid black;
  border-left: none;
  top: -3px;
  right: -9px;
  transform: skewX(342deg);
}

.transformText {
  transform: rotate(180deg);
}

.leftMenu {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid #ff6e30;
  border-radius: 50%;
  color: black;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.7;
}



.scroll-to-top-button:hover {
  opacity: 1;
}



.scroll-to-top-buttonTg {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
  color: black;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.7;
}

.scroll-to-top-buttonTg:hover {
  opacity: 1;
}


.fa-2 {
  position: relative;
  width: 162px;
  cursor: auto;
  height: 49px;
  border: 3px solid #ff6e30;
  background-color: white;
  font-size: 23px;
  font-weight: 500;
  border-right: none;
  transform: rotate(540deg);
  writing-mode: horizontal-tb;
}

.sevenFA2 {
  position: absolute;
  cursor: auto;
  width: 20px;
  height: 49px;
  border-top: 3px solid #ff6e30;
  border-right: 3px solid #ff6e30;
  border-left: none;
  top: -3px;
  right: -9px;
  transform: skewX(342deg);
}

.leftMenu::-webkit-scrollbar {
  width: 0px;
  /* Установите желаемую ширину для скроллбара */
}

.hideScroll::-webkit-scrollbar {
  width: 0px;
  /* Установите желаемую ширину для скроллбара */
}



.titleFund {

  
  @media screen and (min-width: 1024px) and (max-width: 1285px) {
    margin-left: 20px;
  }
}


 

  /* Общие стили, которые будут применяться ко всем разрешениям экрана */
.pngStyle {
  position: absolute; /* или другое подходящее значение */
}

/* Медиа-запросы для разных размеров экрана */
@media screen and (max-width: 767px) {
  .pngStyle {
    left: 0%;
  }
}


/* Медиа-запросы для разных размеров экрана */
@media screen and (min-width: 0px) {
  .pngStyle {
    left: 0px;
  }
}

@media screen and (min-width: 768px) {
  /* Для md размера экрана и больше */
  .pngStyle {
    left: 5px;
  }
}

@media screen and (min-width: 1024px) {
  /* Для lg размера экрана и больше */
  .pngStyle {
    left: 5%;
  }
}





@media screen and (min-width: 1280px) {
  /* Для lg размера экрана и больше */
  .pngStyle {
    left: 16%;
  }
}


@media screen and (min-width: 1440px) {
  /* Для 2xl размера экрана и больше */
  .pngStyle {
    left: 15%;
  }
}







